export default [{
  label: 'Razão social', field: 'razaoSocial',
},
{ label: 'CNPJ/CPF', field: 'cadastroFederal', tdClass: 'tamanho-cnpj' },
{ label: 'PA', field: 'competencia' },
{
  label: 'Valor informado faturamento', field: 'rpa', tdClass: 'col-tamanho-situacao text-center',
},
{
  label: 'Valor principal DAS', field: 'valorDas', tdClass: 'col-tamanho-situacao text-center',
},
{
  label: 'DAS em atraso no PA', field: 'dasEmAtraso', tdClass: 'col-tamanho-situacao text-center', type: 'custom',
},
{
  label: 'Débitos', field: 'hasDebitos', tdClass: 'col-tamanho-situacao text-center', type: 'custom',
},
{ label: 'Consultado em', field: 'consultadoEm' }]

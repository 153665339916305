import axios from '@axios'

const PREFIX = 'das'

export default {

  getPaginado(page, pageSize, filtros) {
    return axios.post(`${PREFIX}/paged?pagina=${page}&tamanhoPagina=${pageSize}`, filtros)
  },

  getURLSimplesNacional(id, competencia) {
    return axios.get(`${PREFIX}/${id}/url-sn?competencia=${competencia}`)
  },

  getURL(id) {
    return axios.get(`${PREFIX}/${id}/url`)
  },

  baixaEmLote(filtros, nomeArquivo) {
    return axios.post(`${PREFIX}/baixa-lotes?nomeArquivo=${nomeArquivo}`, filtros)
  },

  requestGuiasDas(empresaId) {
    return axios.get(`${PREFIX}/guias-das?empresaId=${empresaId}`)
  },

  getObservacoes(id) {
    return axios.get(`${PREFIX}/${id}/observacao`)
  },

  removerObservacao(id) {
    return axios.delete(`${PREFIX}/observacao/${id}/remover`)
  },

  salvarObservacao(observacao) {
    return axios.post(`${PREFIX}/observacao/salvar`, observacao)
  },

  recalcularDas(id, data, razaoSocial) {
    return axios.get(`${PREFIX}/${id}/recalcular?data=${data}&razaoSocial=${razaoSocial}`)
  },

  notificaDas(nomeEmpresa, emailEmpresa, emailCc, idDas, id, mensagemAdicional, definirEmailPadrao) {
    return axios.post(`${PREFIX}/${id}/notifica?idDas=${idDas}&emailEmpresa=${emailEmpresa}&emailCc=${emailCc}&nomeEmpresa=${nomeEmpresa}&mensagemAdicional=${encodeURIComponent(mensagemAdicional)}&definirEmailPadrao=${definirEmailPadrao}`)
  },
  baixarEmExcel(tipoCnd, filtros) {
    return axios.post(`${PREFIX}/${tipoCnd}/baixa-excel`, filtros)
  },

  deletarExcel(url, nomeArquivo) {
    return axios.post(`${PREFIX}/deletar-excel?url=${url}&nomeArquivo=${nomeArquivo}`)
  },
}
